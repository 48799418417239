<template>
  <div class="tobeCenter">
    <h2>
      <i class="icon ion-logo-html5"></i>
      Page is not found
    </h2>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.tobeCenter {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  h2 {
    color: orange;
    .icon {
      color: orange;
      font-size: 35px;
    }
  }
}
</style>
